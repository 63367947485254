export enum Services {
  Cash,
  Matah,
  PayBills,
};

export type ServiceDTO = {
  id: string;
  categoryId: string;
  name: string;
  description?: string;
  logo?: string;
};

export type ServiceCategoryDTO = {
  id: string;
  name: string;
};

export type GetAllServicesResponse = {
  services: ServiceDTO[];
  categories: ServiceCategoryDTO[];
  error?: never;
} | {
  error: string;
  services?: never;
  categories?: never;
}

export type CategoryWithServices = ServiceCategoryDTO & {
  services: ServiceDTO[];
};
