import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PartnerRate } from "types/rates";

interface RatesSlice {
  rates: PartnerRate[] | null;
}

const initialState: RatesSlice = {
  rates: null,
};

export const ratesSlice = createSlice({
  name: "ratesSlice",
  initialState,
  reducers: {
    setPartnerRates: (
      state,
      action: PayloadAction<PartnerRate[] | null>
    ) => {
      state.rates = action.payload;
    },
  },
});

export const { setPartnerRates } = ratesSlice.actions;
export default ratesSlice.reducer;
