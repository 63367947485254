import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentDTO } from "types/components";
import { StackDTO } from "types/stacks";

interface SystemState {
  stacks: StackDTO[] | null;
  components: ComponentDTO[] | null;
}

const initialState: SystemState = {
  stacks: null,
  components: null,
};

export const systemSlice = createSlice({
  name: "systemSlice",
  initialState,
  reducers: {
    setSystemData: (
      state,
      action: PayloadAction<{
        stacks: StackDTO[] | null;
        components?: ComponentDTO[] | null;
      }>
    ) => {
      state.stacks = action.payload.stacks;
      if (action.payload.components !== undefined) {
        state.components = action.payload.components;
      }
    },
  },
});

export const { setSystemData } = systemSlice.actions;
export default systemSlice.reducer;
