import { fetchPartnerRates } from "api/rates";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setPartnerRates } from "store/ratesSlice";
import { useAppDispatch, useAppSelector } from "store/store";

export function useFetchPartnerRates() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { rates } = useAppSelector(state => state.rates);

  useEffect(() => {
    if (!params.partnerId || !dispatch) return;

    const partnerId = params.partnerId || "";
    (async () => {
      const { rates, error } = await fetchPartnerRates(Number(partnerId));

      if (error !== undefined) {
        setError(error);
        setIsLoading(false);
        return;
      }

      dispatch(setPartnerRates(rates));
    })();
  }, [params, dispatch]);

  useEffect(() => {
    if (!rates) return;

    setIsLoading(false);
  }, [rates])

  return {
    isLoading,
    error,
  };
}
