import { getPartnerData } from "api/partnerApi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { setPartnerData } from "store/partnerSlice";

export function useFetchPartnerData() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const partnerData = useAppSelector(state => state.partner.partnerData);

  useEffect(() => {
    const partnerId = params.partnerId || "";
    if (!dispatch || !partnerId) return;

    (async () => {
      try {
        const getPartnerDataResponse = await getPartnerData(Number(params?.partnerId) ?? 0);

        if (getPartnerDataResponse) {
          dispatch(setPartnerData(getPartnerDataResponse));
        }
      } catch (error) {
        console.log(error);
      }
    })();

  }, [params, dispatch]);

  useEffect(() => {
    if (!partnerData) return;

    setIsLoading(false);
  }, [partnerData]);

  return {
    isLoading,
  };
}

