import { getPayoutStatus } from "api/kioskApi";
import Header from "layouts/header/Header";
import { useEffect } from "react";
import Lottie from "react-lottie";
import { setCheckoutStatus } from "store/checkoutSlice";
import { setActionId } from "store/kioskSlice";
import { setCurrentScreen } from "store/navigationSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { CheckoutStatus } from "types/CheckoutStatus";
import { StatusType } from "types/kiosk/status";
import { Screens } from "types/Screens";
import "./payment-success-screen.css";

const PaymentSuccessScreen = () => {
  const dispatch = useAppDispatch();
  const actionId = useAppSelector((state) => state.kiosk.actionId);
  useEffect(() => {
    let attempts = 0; // Track the number of fetch attempts
    const fetchData = async () => {
      try {
        const response = await getPayoutStatus({
          actionId,
        });
        console.log({ response });
        if (response === StatusType.SUCCESS || response === StatusType.UNSPECIFIED) {
          dispatch(setActionId(""));
          dispatch(setCheckoutStatus(CheckoutStatus.SUCCESS));
          dispatch(setCurrentScreen(Screens.CHECKOUT_FINISH));
        } else if (attempts >= 5) {
          clearInterval(interval); // Stop further attempts after 5
          dispatch(setActionId(""));
          dispatch(setCheckoutStatus(CheckoutStatus.SUCCESS));
          dispatch(setCurrentScreen(Screens.CHECKOUT_FINISH));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const interval = setInterval(() => {
      attempts++;
      fetchData();
      if (attempts >= 5) {
        clearInterval(interval); // Stop the interval after 5 attempts
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Header></Header>
      <div className="main-container">
        <div className="header">
          <p className="title">המתן להוצאת השטרות</p>
          <p className="message-text">ברגעים אלו נשלח אליך מסרון עם פרטי העסקה</p>
        </div>

        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: require("../../assets/animations/payment-success-animation.json"),
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={900}
          width={900}
        />

        <div className="bottom-container-b">
          <p>לכל שאלה אנחנו זמינים עבורך</p>
          <div className="contact-info-container">
            <img src={"/mail.svg"} alt="right arrow" />
            <p>support@cashwiz.co.il</p>
          </div>
          |
          <div className="contact-info-container">
            <img src={"/phone.svg"} alt="right arrow" />
            <p>03-5555555</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessScreen;
