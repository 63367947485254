import { ReactNode, useEffect, useMemo, useState } from "react";
import { setCurrentScreen, setService } from "store/navigationSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { Screens } from "types/Screens";
import { CategoryWithServices, Services } from "types/Services";
import ICT from "./../../assets/ICT-logo-blue 2.png";
import arrow_l from "./../../assets/arrow_l.png";
import arrow_r from "./../../assets/arrow_r.png";
import buyme from "./../../assets/buyme.png";
import cash_icon from "./../../assets/cash_icon.png";
import load_card_icon from "./../../assets/load_card_icon.png";
import mony_send from "./../../assets/mony_send.png";
import once_code_icon from "./../../assets/once_code_icon.png";
import passportcard from "./../../assets/passportcard.png";
import pay_express_icon from "./../../assets/pay_express_icon.png";
import progress_p from "./../../assets/progress_p.png";
import progress_w from "./../../assets/progress_w.png";
import rewire from "./../../assets/rewire.png";
import smart_kiosk from "./../../assets/smart_kiosk.png";
import take_icon from "./../../assets/take_icon.png";
import virtual_coin_icon from "./../../assets/virtual_coin_icon.png";
import w_finance from "./../../assets/w_finance.png";
import w_union from "./../../assets/w_union.png";
import "./welcome-screen-new.css";
import ServiceSection from "components/services/ServicesSection";

const WelcomeScreenNew = () => {
  const { services, categories } = useAppSelector(state => state.services)

  const sectionsRendered = useMemo<ReactNode>(() => {
    if (!(services && categories)) return null;

    const sections: CategoryWithServices[] = [];

    categories.forEach(c => {
      sections.push({
        id: c.id,
        name: c.name,
        services: services.filter(s => s.categoryId === c.id),
      });
    });

    return sections.map(
      s => <ServiceSection section={s} key={`section-${s.id}`} />
    );
  }, [services, categories]);

  if (!sectionsRendered) return <></>;

  return (
    <div className="WelcomeScreenNew">
      <header className="header_cont">
        <img src={smart_kiosk} className="smart_kiosk" />
        <h1 className="head_title"> היי, מה ברצונך לבצע? </h1>
      </header>

      {sectionsRendered}

    </div>
  );
};

export default WelcomeScreenNew;
