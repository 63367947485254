// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "./authSlice";
import checkoutReducer from "./checkoutSlice";
import currencyReducer from "./currencySlice";
import kioskReducer from "./kioskSlice";
import navigationReducer from "./navigationSlice";
import partnerReducer from "./partnerSlice";
import paymentsReducer from "./paymentsSlice";
import ratesReducer from "./ratesSlice";
import registersReducer from "./registerSlice";
import servicesReducer from "./servicesSlice";
import systemReducer from "./systemSlice";

const rootReducer = combineReducers({
  currency: currencyReducer,
  navigation: navigationReducer,
  payments: paymentsReducer,
  register: registersReducer,
  partner: partnerReducer,
  services: servicesReducer,
  checkout: checkoutReducer,
  auth: authReducer,
  rates: ratesReducer,
  kiosk: kioskReducer,
  system: systemReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
