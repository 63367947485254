import USD from "../assets/USD.svg";
import EUR from "../assets/EUR.svg";

export function getCurrencyPreview(currency: string): [
  img: string,
  sign: string,
] {
  switch (currency) {
    // case "AED": return [AED, "د.إ"];
    // case "BGN": return [BGN, ".лв"];
    // case "CAD": return [CAD, "C$"];
    // case "CHF": return [CHF, "Fr."];
    // case "CZK": return [CZK, "Kč"];
    // case "EGP": return [EGP, "E£"];
    // case "GBP": return [GBP, "£"];
    // case "GEL": return [GEL, "₾"];
    // case "HUF": return [HUF, "Ft"];
    // case "ILS": return [ILS, "₪"];
    // case "PLN": return [PLN, "zł"];
    // case "RON": return [RON, "L"];
    // case "THB": return [THB, "฿"];
    // case "TRY": return [TRY, "₺"];
    case "EUR": return [EUR, "€"];
    case "USD": return [USD, "$"];
    default: return [USD, "$"];
  }
}
