import "./operation-system-tabs.css";
import { useAppDispatch, useAppSelector } from "store/store";
import { setCurrentScreen } from "store/navigationSlice";
import { Screens } from "types/Screens";
import approve_input from './../../assets/approve_input.png'
import cancel_input from './../../assets/cancel_input.png'
import exit_icon from './../../assets/exit_icon.png'
import refreshIcon from "../../assets/refresh.svg";
import banknotes_white from './../../assets/banknotes_white.png'
import { ReactComponent as Arrow } from "./../../assets/arrow.svg"
import problem_report_icon from './../../assets/problem_report_icon.png'

import coins_white from './../../assets/coins_white.png'
import Header from "layouts/header/Header";
import { useCallback, useEffect, useMemo, useState } from "react";
import status_icon from './../../assets/status_icon.png'

import Button from "lib/button";
import NumericKeypadSmall from "components/buying/numeric-keypad-small/numeric-keypad-small";
import { checkHealth } from "api/components";
import { ComponentDTO } from "types/components";
import { getComponentStatusImg, getComponentStatusText, getComponentTypeName } from "utils/components";
import { fetchStacks, updateStacks } from "api/stacks";
import { StackDTO, UpdateStackPayload } from "types/stacks";
import StackRow from "components/stacks/StackRow";
import { useFetchSystemData } from "hooks/system";
import { setSystemData } from "store/systemSlice";

enum Tab {
  Health,
  Coins,
  Bills,
  Report,
};

const OperationSystemTabs = () => {
  const dispatch = useAppDispatch();
  const [selectedTab, setselectedTab] = useState<Tab>(Tab.Health);
  const token = useAppSelector((state) => state.auth.adminToken);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const systemDataQuery = useFetchSystemData();

  const [updatedStacks, setUpdatedStacks] = useState<UpdateStackPayload[]>([]);
  const [inEditing, setInEditing] = useState<number | null>(null);

  const { stacks, components } = useAppSelector(state => state.system);

  const handleEdit = (id: number) => {
    const stack = stacks?.find(s => s.id === id);
    if (!stack) return;

    setUpdatedStacks(prev => ([...prev, { id, count: String(stack.count) }]));
    setInEditing(id);
  }

  const handleReset = (id: number) => {
    setUpdatedStacks(prev => prev.filter(p => p.id !== id));
    setInEditing(null);
  }

  const billStacks = useMemo(() => {
    if (!stacks) return null;

    return stacks.filter(s => s.form === "BILLS");
  }, [stacks]);

  const coinStacks = useMemo(() => {
    if (!stacks) return null;

    return stacks.filter(s => s.form === "COINS");
  }, [stacks]);

  const saveStacks = useCallback(async () => {
    setIsUpdating(true);
    const { data, error } = await updateStacks({
      token: token || "",
      stacks: updatedStacks,
    });

    if (error) {
      console.log(`Error when updating stacks: ${error}`);
      setIsUpdating(false);
      return;
    }

    const ids = data?.map(s => s.id) || [];
    dispatch(setSystemData({
      stacks: stacks?.map(
        s => {
          if (!ids.includes(s.id)) return s;

          const newStack = data?.find(u => u.id === s.id);
          if (!newStack) return s;

          const updated = {
            ...s,
            count: newStack.count,
          };

          return updated;
        }
      ) || []
    }));
    setselectedTab(Tab.Health);
    setIsUpdating(false);

  }, [
    stacks,
    token,
    updatedStacks,
  ]);

  const switchTab = (tab: Tab) => {
    setUpdatedStacks([]);
    setselectedTab(tab);
  }

  const handleInput = (value: string) => {
    if (inEditing === null) return;

    setUpdatedStacks(prev => {
      const updated = [...prev];
      const existing = updated.find(s => s.id === inEditing);

      if (!existing) {
        console.log('No payload found');
        return prev;
      }
      existing.count = `${existing.count}${value}`;

      return updated;
    });
  }

  const handleErase = () => {
    if (inEditing === null) return;

    setUpdatedStacks(prev => {
      const updated = [...prev];
      const existing = updated.find(s => s.id === inEditing);

      if (!existing) {
        console.log('No payload found');
        return prev;
      }

      existing.count = existing.count?.slice(0, -1) || "";

      return updated;
    });
  }

  return (
    <>
      <Header show_logo={false} user_name="שם: קיוסק השרון"></Header>
      <div className="OperationSystemTab">
        <div className="WelcomeScreenSecond_first_part">

          <div className="tabs_cont">
            <div className={"one_tab tab_1 " + (selectedTab === Tab.Health ? "selected_tab_blue" : "")} onClick={() => switchTab(Tab.Health)}>
              <img src={status_icon} className="status_icon" />
              סטטוס</div>

            <div className={"one_tab tab_2 " + (selectedTab === Tab.Coins ? "selected_tab_blue" : "")} onClick={() => switchTab(Tab.Coins)}>
              <img src={coins_white} className="status_icon" />
              הטענות מטבעות</div>

            <div className={"one_tab tab_3 " + (selectedTab === Tab.Bills ? "selected_tab_blue" : "")} onClick={() => switchTab(Tab.Bills)}>
              <img src={banknotes_white} className="status_icon" />
              הטענות שטרות</div>

            <div className={"one_tab tab_4 " + (selectedTab === Tab.Report ? "selected_tab_4" : "")} onClick={() => switchTab(Tab.Report)}>
              <img src={problem_report_icon} className="problem_report_icon" />
            </div>

          </div>

          {selectedTab === Tab.Health ? <div className="table_status_cont">
            <div className="table_status_header">
              <div className="table_status_line_width_first">רכיב</div>
              <div className="table_status_line_width">קוד</div>
              <div className="table_status_line_width_last">סטטוס</div>
            </div>

            {
              components?.map((c, i) => (
                <div
                  key={`component-row-${i + 1}`}
                  className="table_status_line"
                >
                  <div className="table_status_line_width_first">
                    {getComponentTypeName(c.componentType)}
                  </div>
                  <div className="table_status_line_width">{c.code || ""}</div>
                  <div className="table_status_line_width_last">
                    <img src={getComponentStatusImg(c.status)} />
                    <span className="active_text">
                      {getComponentStatusText(c.status)}
                    </span>
                  </div>
                </div>
              ))
            }
          </div> : <></>}

          {selectedTab === Tab.Coins ? <>

            <div className="table_status_cont">

              <div className="table_status_header_sec">
                <div className="table_status_line_width_first_sec">סוג שטר</div>
                <div className="table_status_line_width_long">כמות מטבעות במחסנית</div>
                <div className="table_status_line_width">סכום</div>
                <div className="table_status_line_count">הטענה/ריקון</div>
                <div className="table_status_line_width"></div>
              </div>

              {
                coinStacks?.map((s, i) => {
                  return <StackRow
                    disabled={isUpdating}
                    onReset={() => handleReset(s.id)}
                    key={`coin-row-${i}`}
                    stack={s}
                    onEdit={() => handleEdit(s.id)}
                    onFocus={() => setInEditing(s.id)}
                    updatedCount={updatedStacks.find(p => p.id === s.id)?.count}
                  />
                })
              }
            </div>
            <NumericKeypadSmall setValue={handleInput} cancel_caracter={handleErase} />

            <Button
              onClick={() => saveStacks()}
              style={{
                width: '344px', marginTop: '50px'
              }}
              disabled={isUpdating}
            >
              אישור
              <Arrow />

            </Button>

          </> : <></>}

          {selectedTab === Tab.Bills ? <>

            <div className="table_status_cont">
              <div className="table_status_header_sec">
                <div className="table_status_line_width_first_sec">סוג שטר</div>
                <div className="table_status_line_width_long">כמות מטבעות במחסנית</div>
                <div className="table_status_line_width">סכום</div>
                <div className="table_status_line_count">הטענה/ריקון</div>
                <div className="table_status_line_width"></div>
              </div>

              {
                billStacks?.map((s, i) => (
                  <StackRow
                    disabled={isUpdating}
                    onReset={() => handleReset(s.id)}
                    key={`bill-row-${i}`}
                    stack={s}
                    onEdit={() => handleEdit(s.id)}
                    onFocus={() => setInEditing(s.id)}
                    updatedCount={updatedStacks.find(p => p.id === s.id)?.count}
                  />
                ))
              }
            </div>
            <NumericKeypadSmall setValue={handleInput} cancel_caracter={handleErase} />

            <Button onClick={() => saveStacks()} style={{
              width: '344px', marginTop: '50px'
            }}>
              אישור
              <Arrow />

            </Button>

          </> : <></>}

          {selectedTab === Tab.Report ?
            <div>
              <div className="mid_title">
                דיווח על בעיה
              </div>

              <select className="operation_code_input ">
                <option className="operation_code_option"> בחר </option>
                <option className="operation_code_option"> Dispenser </option>
                <option className="operation_code_option"> Acceptor </option>
                <option className="operation_code_option"> Camera </option>
                <option className="operation_code_option"> NFC </option>
                <option className="operation_code_option"> Printer </option>
              </select>

              <div className="buttons-container-currency-total">

                <Button onClick={() => { }} style={{
                  width: '344px'
                }}>
                  דיווח
                  <Arrow />

                </Button>

              </div>

            </div> : <></>}

        </div>

        <img src={exit_icon} className="exit_icon" onClick={() => dispatch(setCurrentScreen(Screens.OPERATION_SYSTEM_EXIT))}
        />

        <button className="refresh_btn" onClick={systemDataQuery.refetch} disabled={systemDataQuery.isLoading}>
          <img src={refreshIcon} alt="refresh" />
        </button>

      </div>
    </>
  );
};

export default OperationSystemTabs;
