import { ReactNode, useMemo, useState } from "react";
import { locales } from "services/i18n";
import { CategoryWithServices } from "types/Services";
import styles from "./ServicesSection.module.scss";
import arrowLeft from "../../assets/carouselArrow.svg";
import cashwizIcon from "../../assets/cash_icon.png";
import { getServiceNavData } from "utils/services";
import { useAppDispatch } from "store/store";
import { setCurrentScreen, setService } from "store/navigationSlice";

type Props = {
  section: CategoryWithServices;
};

const perPage = 3;

export default function ServiceSection({
  section,
}: Props) {
  const dispatch = useAppDispatch();
  const isSlider = section.services.length > perPage;
  const [page, setPage] = useState<number>(0);

  const servicesRendered = useMemo<ReactNode>(() => {
    const start = page * perPage;
    const services = section.services.slice(start, start + perPage);

    const rendered = services.map(
      service => {
        const name = (locales.he.services[service.name] || service.name) as string;
        const navData = getServiceNavData(service);
        const onClick = navData ? () => {
          const [navService, navScreen] = navData;
          dispatch(setService(navService));
          dispatch(setCurrentScreen(navScreen));
        } : undefined;
        return (
          <button
            key={`service-card-${service.id}`}
            className={styles.service}
            onClick={onClick}
          >
            <div className={styles.service__card}>
              <img
                className={styles.service__img}
                alt={`${service.name} service`}
                src={service.logo || cashwizIcon}
              />
            </div>
            <h3 className={styles.service__name}>{name}</h3>
            {
              service.description &&
              <p className={styles.service__description}>
              {service.description}
              </p>
            }

          </button>
        );
      }
    );

    return <div className={styles.slider__content}>{rendered}</div>
  }, [section, page]);

  const dotsRendered = useMemo<ReactNode>(() => {
    if (!isSlider) return null;

    const rendered: ReactNode[] = [];
    const total = Math.ceil(section.services.length / perPage);

    for (let i = 0; i < total; i++) {
      const isActive = page === i;

      rendered.push(
        <div className={
          isActive ?
            styles.slider__dots__dot :
            styles.slider__dots__dotActive
        }
          key={`dot-${i}`}
        />
      );
    }

    return (
      <div className={styles.slider__dots}>
        {rendered}
      </div>
    );
  }, [section, page, isSlider]);

  const switchPage = (step: -1 | 1) => {
    const total = Math.ceil(section.services.length / perPage);
    if (!total) return;

    setPage(prev => {
      const next = prev + step;
      if (next > total - 1) return 0;

      if (next < 0) return total - 1;

      return next;
    });
  }

  const title = (locales.he.serviceCategories[section.name] || section.name) as string;

  return <section className={styles.section}>
    <h2 className={styles.section__name}>{title}</h2>
    <div className={styles.slider}>
      <div className={styles.slider__contentWrapper}>
        {
          isSlider && <button
            className={styles.slider__arrowLeft}
            onClick={() => switchPage(-1)}
          >
            <img src={arrowLeft} alt="Prev" />
          </button>
        }
        {servicesRendered}
        {
          isSlider && <button
            className={styles.slider__arrowRight}
            onClick={() => switchPage(1)}
          >
            <img src={arrowLeft} alt="Next" />
          </button>
        }

      </div>
      {
        isSlider &&
        dotsRendered
      }
    </div>
  </section>;
}
