import axios, { AxiosError } from "axios";
import { FetchPartnerRatesResponse } from "types/rates";
import { baseUrl } from "./config";

export async function fetchPartnerRates(partnerId: number): Promise<
  FetchPartnerRatesResponse
> {
  try {
    const response = await axios.get(
      `${baseUrl}/rates?partnerId=${partnerId}`,
    );

    return {
      rates: response.data.rates || [],
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error: error.response?.data.error ||
          error.message || "Something went wrong",
      };
    }

    console.error(`Unexpected error when fetching partner rates: ${error}`);

    return {
      error: "Something went wrong",
    };
  }
}
