import { useMemo, useState } from "react";
import { StackDTO } from "types/stacks";
import { getStackPreview } from "utils/stacks";
import pen from "../../assets/pen.png";
import "./StackRow.css";

type Props = {
  stack: StackDTO;
  onEdit: () => void;
  onFocus: () => void;
  updatedCount?: string;
  onReset: () => void;
  disabled?: boolean;
};

export default function StackRow({
  stack,
  onEdit,
  onFocus,
  updatedCount,
  onReset,
  disabled,
}: Props) {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [img, imgClass, sign, amount] = useMemo(() => {
    return getStackPreview(stack)
  }, [stack]);

  const onToggleEdit = () => {
    if (isEditing) {
      setIsEditing(false);
      onReset();
    } else {
      setIsEditing(true);
      onEdit();
    }
  }

  const countRendered = useMemo(() => {
    if (!isEditing) {
      return <div className="stack-row-count">{
        stack.count.toLocaleString(
          undefined,
          {
            maximumFractionDigits: 3,
          }
        )
      }</div>;
    }

    return <input
      onFocus={(event) => {
        const input = event.target;
        const length = input.value.length;
        input.setSelectionRange(length, length);
        onFocus();
      }}
      onClick={(event) => { event.preventDefault() }}
      disabled={disabled}
      className="stack-row-input"
      value={updatedCount}
      readOnly
    />;
  }, [isEditing, stack.count, disabled, updatedCount, onFocus]);

  return (
    <div
      className="stack-row"
    >
      <div className="stack-row-col-first">
        <img src={img} className={imgClass} alt="Currency icon" />
      </div>
      <div className="stack-row-col-long">{stack.count}</div>
      <div className="stack-row-col">
        <span className="stack-amount">{amount.toLocaleString(
          undefined,
          {
            maximumFractionDigits: 3,
          }
        )} </span>
        <span>{sign} </span>
      </div>
      {countRendered}
      <button
        className="stack-row-col-last"
        onClick={onToggleEdit}
      >
        <img
          src={pen}
          alt="Edit stack"
        />
      </button>
    </div>
  );
}
