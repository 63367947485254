import axios, { AxiosError } from "axios";
import { GetAllServicesResponse } from "types/Services";
import { baseUrl } from "./config";

export async function fetchServices({
  partnerId,
}: {
  partnerId?: string;
}): Promise<
  GetAllServicesResponse
> {
  try {
    const response = await axios.get(`${baseUrl}/services?partnerId=${partnerId}`);

    return {
      services: response.data.services,
      categories: response.data.categories,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error: error.response?.data.error ||
          error.message || "Something went wrong",
      };
    }

    console.error(`Unexpected error when fetching stacks: ${error}`);

    return {
      error: "Something went wrong",
    };
  }
}

