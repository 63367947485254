import AuthWatcher from "components/AuthWatcher";
import Buying from "modules/bying/buying";
import { useState } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { store } from "store/store";
import "./App.css";
import MainLayout from "./layouts/main-layout/main-layout";

function App() {
  const [show, setShow] = useState(false);
  function setShow_func() {
    setShow(true);
  }

  // const handleContextMenu = (event: MouseEvent) => {
  //   event.preventDefault();
  // }
  // useEffect(() => {
  //   window.addEventListener("contextmenu", handleContextMenu);

  //   return () => window.removeEventListener("contextmenu", handleContextMenu);
  // }, []);
  return (
    <Provider store={store}>
      <div className="App">
        <MainLayout setShow={setShow_func}>
          <AuthWatcher />
          <Router>
            <Routes>
              <Route path="/:partnerId" element={<Buying show={show} setShow={setShow} />} />
            </Routes>
          </Router>
        </MainLayout>
      </div>
    </Provider>
  );
}

export default App;
