import { fetchServices } from "api/services";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setServicesData } from "store/servicesSlice";
import { useAppDispatch, useAppSelector } from "store/store";

export function useFetchServices() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { services, categories } = useAppSelector(state => state.services);

  useEffect(() => {
    if (!params.partnerId) return;

    const partnerId = params.partnerId || "";
    (async () => {
      const { services, categories, error } = await fetchServices({ partnerId });

      if (error !== undefined) {
        setError(error);
        setIsLoading(false);
        return;
      }

      dispatch(setServicesData({ services, categories }));
    })();
  }, [params]);

  useEffect(() => {
    if (!(services && categories)) return;

    setIsLoading(false);
  }, [services, categories])

  return {
    isLoading,
    error,
  };
}
