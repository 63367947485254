type Values = {
  [key: string]: Values | string;
}

export const locales = {
  he: {
    serviceCategories: {
      "foreign_exchange": "העברות לחו”ל",
      "transfers_abroad": "רכישות מט”ח",
      "credit_card": "הטענה כרטיסי אשראי",
      "payments": "תשלומים",
    } as Values,
    services: {
      "crypto_purchase": "מטבעות וירטואליות רכישה/מכירה",
      "buy_me_payments": "מתנות לכל אירוע",
      "payxpress_payments": "לשלם חשבונות",
      "money_send_transfers": "העברות כספים לחו”ל",
      "cashwiz_credit_card_exchange": " לרכוש מט\"ח  בכרטיס אשראי",
      "worldcom_finance_transfers": "העברות כספים לחו”ל",
      "ict_transfers": "העברות כספים לחו”ל",
      "cashwiz_exchange": "המרת מטבע Currency Exchange",
      "western_union_transfers": "העברות כספים לחו”ל",
      "passport_card_payments": "ביטוח נסיעות לחו”ל",
      "website_exchange": "לאסוף את מט\"ח שרכשתי באתר",
      "rewire_transfers": "העברות כספים לחו”ל",
      "one_time_code_withdrawal": " משיכה באמצעות קוד חד פעמי",
      "card_recharge": "להטעין כרטיס פריפייד",
    } as Values,
  },
};
