import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface KioskState {
  actionId: string;
}

const initialState: KioskState = {
  actionId: "",
};

export const currencySlice = createSlice({
  name: "kioskSlice",
  initialState,
  reducers: {
    setActionId: (state, action: PayloadAction<string>) => {
      state.actionId = action.payload;
    },
  },
});

export const { setActionId } = currencySlice.actions;
export default currencySlice.reducer;
