import { checkHealth } from "api/components";
import { fetchStacks } from "api/stacks";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setSystemData } from "store/systemSlice";

export function useFetchSystemData() {
  const token = useAppSelector((state) => state.auth.adminToken);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { stacks, components } = useAppSelector(state => state.system);

  const refetch = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    dispatch(setSystemData({
      stacks: null,
      components: null,
    }));

    const { data: components } = await checkHealth(token);
    if (!components) {
      console.log('No health data');
      return;
    }

    const { data: stacks } = await fetchStacks({ token });
    if (!stacks) {
      console.log('No stacks data');
      return;
    }

    dispatch(setSystemData({
      stacks: stacks || [],
      components: components || [],
    }));
  }, [token]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!(stacks && components)) return;

    setIsLoading(false);
  }, [stacks, components])

  return {
    isLoading,
    refetch,
  };
}

