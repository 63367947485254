import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceCategoryDTO, ServiceDTO } from "types/Services";

interface ServicesState {
  services: ServiceDTO[] | null;
  categories: ServiceCategoryDTO[] | null;
}

const initialState: ServicesState = {
  services: null,
  categories: null,
};

export const servicesSlice = createSlice({
  name: "servicesSlice",
  initialState,
  reducers: {
    setServicesData: (
      state,
      action: PayloadAction<{
        services: ServiceDTO[] | null;
        categories: ServiceCategoryDTO[] | null;
      }>
    ) => {
      state.services = action.payload.services;
      state.categories = action.payload.categories;
    },
  },
});

export const { setServicesData } = servicesSlice.actions;
export default servicesSlice.reducer;

