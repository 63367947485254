import { setCurrentScreen } from "store/navigationSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { Screens } from "types/Screens";
import card_icon from "./../../assets/card_icon.png";
import change_icon_orange from "./../../assets/change_icon_orange.png";
import left_arrow_orange from "./../../assets/left_arrow_orange.png";
import phones_icon from "./../../assets/phones_icon.png";
import qrcode_icon from "./../../assets/qrcode_icon.png";
import "./welcome-screen-second.css";

import ErrorScrenLeftModal from "components/buying/error-modal-screen-left";
import Header from "layouts/header/Header";
import { useEffect, useState } from "react";
import { getCurrencyPreview } from "utils/currencies";

const WelcomeScreenSecond = () => {
  const dispatch = useAppDispatch();

  const [showScreenError, setshowScreenError] = useState(false);
  const { rates } = useAppSelector((store) => store.rates);

  const [timeoutID, settimeoutID] = useState<any>();

  useEffect(() => {
    setTimeout(() => {
      setshowScreenError(true);
    }, 60000);
  }, []);

  useEffect(() => {
    if (!showScreenError) {
      clearTimeout(timeoutID);
      return;
    }

    settimeoutID(
      setTimeout(() => {
        dispatch(setCurrentScreen(Screens.WELCOME_SCREEN));
      }, 30000)
    );
  }, [showScreenError]);

  return (
    <>
      <Header />
      <div className="WelcomeScreenSecond">
        <div className="WelcomeScreenSecond_first_part">
          <div className="mid_title"> קניית מט״ח באמצעות כרטיס אשראי ובתשלומים</div>

          <div className="rates-titles-container">
            <div className="rates-title-container">
              <img src={change_icon_orange} alt="change icon" />
              <h3 className="rates-title">שערי המרה משתלמים במיוחד! </h3>
            </div>

            <div className="rates-title-container">
              <img src={card_icon} alt="card icon" />
              <h3 className="rates-title"> אפשרות לפריסה עד 12 תשלומים. </h3>
            </div>
          </div>

          <table className="rates-table">
            <tbody>
              <tr>
                <td className="rates-subtitle">שער מכירה</td>
                <td />
              </tr>
              {rates?.map((r) => {
                const [img, sign] = getCurrencyPreview(r.currencyName);
                const price = r.baseRate + r.baseRate * 0.01 * r.rateProfit;
                return (
                  <tr key={`partner-rate-${r.currencyName}`}>
                    <td>
                      <span>{price.toFixed(4)} </span>
                      <span className="sign">₪</span>
                    </td>
                    <td>
                      <div className="currency-preview">
                        <p>{sign} 1</p>
                        <img src={img} alt={`${r.currencyName} icon`} />
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr></tr>
              <tr></tr>
            </tbody>
          </table>

          <button
            className="start_buy_btn"
            onClick={() => {
              dispatch(setCurrentScreen(Screens.USER_DETAILS));
            }}
          >
            {" "}
            אני רוצה נתחיל ברכישה{" "}
          </button>
        </div>

        <div className="WelcomeScreenSecond_sec_part">
          <div className="mid_title">ניתן גם לבצע את הרכישה באתר ולאסוף את הכסף בזמן שנוח עבורך </div>

          <div className="images_phones_cont">
            <img src={phones_icon} />
            <img src={left_arrow_orange} className="left_arrow_orange" />
            <div className="qrcode_icon_cont">
              <img src={qrcode_icon} />
              <div className="site_address">www.cashwiz.co.il</div>
            </div>
          </div>
        </div>
      </div>

      <ErrorScrenLeftModal show={showScreenError} setShow={setshowScreenError} />
    </>
  );
};

export default WelcomeScreenSecond;
