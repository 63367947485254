export enum Screens {
  ON_BOARDING,
  PHONE_RECORDING,
  PHONE_SUCCESS,
  CHOOSE_CURRENCY,
  CHOOSE_AMOUNT,
  ORDER_SUMMARY,
  PAYMENT,
  PAYMENT_SUCCESS,
  WELCOME_SCREEN,
  WELCOME_SCREEN_SECOND,
  USER_DETAILS,
  INSERT_CODE,
  CHOOSE_REGISTER_OPTION,
  QR_REGISTER,
  LINK_REGISTER,
  REGISTER_HERE,
  DOCUMENT_TYPE,
  SCAN_VIDEO,
  SCAN_DOC,
  SCAN_SUCCESS,
  SCAN_FACE,
  FINAL_FACE_DOC,
  FINISH_REGISTER,
  SEND_OTP_EXISTED,
  NOT_MY_NUM,
  SCAN_FACE_USER_EXIST,
  CHECKOUT_FINISH,
  SCAN_SELECT_MATAH,
  INSERT_BANKNOTES,
  YOU_CHOOSE_MATAH,
  TOTAL_MATAH,
  GET_MATAH,
  FINISH_MATAH,
  WELCOME_SCREEN_MATAH,
  OPERATION_SYSTEM,
  CODE_ERR,
  OPERATION_SYSTEM_TABS,
  OPERATION_SYSTEM_EXIT,
  PAY_BILLS,
  CHOOSE_SUPPLIER,
  SUPPLIER_ORDER_DETAILS,
  SUPPLIER_ORDER_PERSONAL_DETAILS,
  SUPPLIER_ORDER_CHOOSE_PAYMENT,
  SUPPLIER_ORDER_CASH_PAYMENT,
  WAIT_LOADING_DATA,
  TAKE_REST_PAYMENT
  
}

