import { StackDTO } from "types/stacks";
import usd_100 from '../assets/usd_100.png'
import euro_50 from '../assets/euro_50.png'
import shekel_100 from '../assets/shekel_100.png'
import shekel_50 from '../assets/shekel_50.png'
import shekel_icon from '../assets/shekel_icon.png'
import ten_shekel from '../assets/ten_shekel.png'
import five_shekel from '../assets/five_shekel.png'
import two_shekel from '../assets/two_shekel.png'

export function getStackPreview(stack: StackDTO): [
  string,
  string,
  string,
  number,
] {
  let img: string;
  let imgClass: string;
  let sign: string;
  let amount: number;
  if (stack.form === "BILLS") {
    switch (stack.stackType) {
      case "HUNDRED_USD": {
        img = usd_100;
        imgClass = "usd_100";
        sign = "$";
        amount = 100 * stack.count;
        break;
      }
      case "FIFTY_EUR": {
        img = euro_50;
        imgClass = "euro_50";
        sign = "€";
        amount = 50 * stack.count;
        break;
      }
      case "HUNDRED_EUR": {
        img = euro_50;
        imgClass = "euro_50";
        sign = "€";
        amount = 100 * stack.count;
        break;
      }
      case "FIFTY_ILS": {
        img = shekel_50;
        imgClass = "shekel_50";
        sign = "₪";
        amount = 50 * stack.count;
        break;
      }
      case "HUNDRED_ILS": {
        img = shekel_100;
        imgClass = "shekel_100";
        sign = "₪";
        amount = 100 * stack.count;
        break;
      }
      default: {
        img = usd_100;
        imgClass = "usd_100";
        amount = 100;
        sign = "$";
      }
    }
  } else {
    sign = "₪";
    imgClass = "";
    switch (stack.stackType) {
      case "ONE_ILS": {
        img = shekel_icon;
        amount = stack.count;
        break;
      }
      case "TWO_ILS": {
        img = two_shekel;
        amount = 2 * stack.count;
        break;
      }
      case "FIVE_ILS": {
        img = five_shekel;
        amount = 5 * stack.count;
        break;
      }
      case "TEN_ILS": {
        img = ten_shekel;
        amount = 10 * stack.count;
        break;
      }
      default: {
        img = shekel_icon;
        amount = stack.count;
      }
    }
  }

  return [img, imgClass, sign, amount];
}
