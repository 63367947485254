import { Screens } from "types/Screens";
import { ServiceDTO, Services } from "types/Services";

export function getServiceNavData(service: ServiceDTO): [
  Services,
  Screens,
] | void {
  switch (service.name) {
    case "cashwiz_exchange":
      return [Services.Matah, Screens.WELCOME_SCREEN_MATAH];
    case "cashwiz_credit_card_exchange":
      return [Services.Cash, Screens.WELCOME_SCREEN_SECOND];
    case "payxpress_payments":
      return [Services.PayBills, Screens.PAY_BILLS];
  }
}
