import axios, { AxiosError, AxiosResponse } from "axios";
import { PayoutStatusRequest, PayoutStatusResponse } from "types/kiosk/payoutStatus";
import { baseUrl } from "./config";

export const getPayoutStatus = async (args: { actionId: string }) => {
  try {
    const response = await axios.post<PayoutStatusRequest, AxiosResponse<PayoutStatusResponse>>(baseUrl + "/payout-status", args, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response?.data?.status;
  } catch (error) {
    const errorData = error as AxiosError;
    if (errorData?.response?.status === 503) {
      return {
        success: false,
        message: "STACK_ERROR",
      };
    } else {
      return {
        success: false,
        message: "PAYMENT_ERROR",
      };
    }
  }
};
