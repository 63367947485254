import axios, { AxiosError } from "axios";
import { count } from "console";
import { FetchStacksResponse, StackMoneyForm, UpdateStackPayload } from "types/stacks";
import { baseUrl } from "./config";

export async function fetchStacks({ token, form }: { token: string; form?: StackMoneyForm }): Promise<FetchStacksResponse> {
  try {
    let query = "";
    if (form) {
      query = `?form=${form}`;
    }
    const response = await axios(`${baseUrl}/stacks${query}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      data: response.data.stacks || [],
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error: error.response?.data.error || error.message || "Something went wrong",
      };
    }

    console.error(`Unexpected error when fetching stacks: ${error}`);

    return {
      error: "Something went wrong",
    };
  }
}

export async function updateStacks({ token, stacks }: { token: string; stacks: UpdateStackPayload[] }): Promise<FetchStacksResponse> {
  try {
    const response = await axios(`${baseUrl}/stacks`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        stacks: stacks.map(stack => ({ ...stack, count: Number(stack.count || 0) })),
      },
    });

    return {
      data: response.data.stacks || [],
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error: error.response?.data.error || error.message || "Something went wrong",
      };
    }

    console.error(`Unexpected error when updating stacks: ${error}`);

    return {
      error: "Something went wrong",
    };
  }
}
